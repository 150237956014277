import { CSS, VariantProps, styled } from "@givenwell/stitches";
import { colors } from "@givenwell/theme";
import { IconChevronDown } from "@tabler/icons-react";
import { ComponentPropsWithRef, ForwardedRef, forwardRef, useContext } from "react";
import { FieldContext } from ".";

export type SelectProps = Omit<ComponentPropsWithRef<"select">, "size"> & {
  size?: VariantProps<typeof Root>["size"];
  css?: CSS;
  invalid?: boolean;
  onValueChange?: (value: string) => void;
};

export const Select = forwardRef(function Select(
  { size, invalid, onValueChange, css, ...props }: SelectProps,
  ref: ForwardedRef<HTMLSelectElement>,
) {
  const { inputProps } = useContext(FieldContext);
  return (
    <Root size={size} css={css}>
      <StyledInput
        {...inputProps}
        invalid={invalid}
        {...props}
        ref={ref}
        onChange={e => {
          props.onChange?.(e);
          onValueChange?.(e.target.value);
        }}
      />
      <RightIcon>
        <IconChevronDown />
      </RightIcon>
    </Root>
  );
});

const Root = styled("div", {
  pos: "relative",
  w: "100%",
  variants: {
    size: {
      xs: {
        "--height": "24px",
        "--padding": "8px",
        "--rounded": "2px",
        fontScale: "xs",
      },
      sm: {
        "--height": "32px",
        "--padding": "12px",
        "--rounded": "4px",
        fontScale: "sm",
      },
      md: {
        "--height": "40px",
        "--padding": "16px",
        "--rounded": "8px",
        fontScale: "md",
      },
      lg: {
        "--height": "48px",
        "--padding": "16px",
        "--rounded": "8px",
        fontScale: "lg",
      },
    },
  },
  defaultVariants: {
    size: "md",
  },
});
const StyledInput = styled("select", {
  appearance: "none",
  minW: 0,
  w: "100%",
  height: "var(--height)",
  rounded: "var(--rounded)",
  px: "var(--padding)",
  bg: "transparent",
  color: colors.gray900,
  border: `1px solid ${colors.gray200}`,
  "&::placeholder": {
    color: colors.gray400,
  },

  caretColor: colors.blue500,
  "&:focus": {
    outline: "none",
    borderColor: colors.blue500,
    boxShadow: `0 0 0 1px ${colors.blue500}`,
  },

  variants: {
    invalid: {
      true: {
        caretColor: colors.red400,
        borderColor: colors.red400,
        boxShadow: `0 0 0 1px ${colors.red400}`,
        "&:focus": {
          outline: "none",
          borderColor: colors.red400,
          boxShadow: `0 0 0 1px ${colors.red400}`,
        },
      },
    },
  },
});

const RightIcon = styled("div", {
  pos: "absolute",
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  pointerEvents: "none",
  width: "var(--height)",
  color: colors.gray900,
});
