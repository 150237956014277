import { CreateEapPeriodRequest, UpdateSupporterEapSettingsBody } from "@givenwell/management-api";
import { queryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { api } from "./utils";

export const supporterEAPSettingsQuery = (supporterId: string) =>
  queryOptions({
    queryKey: ["supporter-eap-settings", supporterId],
    queryFn: async () => {
      return await api.supporterEap.getSupporterEapSettings({
        supporterId: supporterId,
      });
    },
  });

export function useSupporterEAPSettingsQuery(supporterId: string) {
  return useQuery(supporterEAPSettingsQuery(supporterId));
}

export function useSupporterEAPPeriodsQuery(supporterId: string) {
  return useQuery({
    queryKey: ["supporter-eap-periods", supporterId],
    queryFn: async () => {
      return await api.supporterEap.getSupporterEapPeriods({
        supporterId: supporterId,
      });
    },
    select: data => data.periods,
  });
}

export function useSupporterEAPSettingsMutation() {
  return useMutation({
    mutationFn: async ({ supporterId, body }: { supporterId: string; body: UpdateSupporterEapSettingsBody }) => {
      return await api.supporterEap.updateSupporterEapSettings({
        supporterId,
        requestBody: body,
      });
    },
  });
}

export function useCreateEAPPeriodMutation(supporterId: string) {
  return useMutation({
    mutationFn: async (body: CreateEapPeriodRequest) => {
      return await api.supporterEap.createEapPeriod({
        supporterId,
        requestBody: body,
      });
    },
  });
}
